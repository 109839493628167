import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './css.module.css';
import { custom, save } from '../materialUI/customStyle';
import Api from '../helper/Api';
import { toast } from 'react-toastify';
import axios from 'axios';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "29px 22px",
  height: "fit-content",
  display: "block",
  width:'450px',
  borderRadius: "6px",
  "&:focus": {
      outline: "none",
  },
};

const DeleteSurvey = ({handledltClose, dltOpen, surveyToDelete, refresh, setRefresh}) => {
  const api = new Api();
//   console.log(surveyToDelete)
  const [dltdata, setdltdata] = useState(null)
useEffect(() => {
    setdltdata(surveyToDelete)
}, [surveyToDelete])
console.log(dltdata)
  const handleDelete = async() => {
    try{
        const {data, status} = await api.deleteSurveyForm(dltdata);
        if(status === 200){
            toast('deleted Category successfully')
      setRefresh(!refresh)
      handledltClose();
        }
    }catch(err){
        console.log(err.response.data.message)
    }
  }
  return (
    <Modal
        open={dltOpen}
        onClose={handledltClose}
      >
        <Box sx={style}>
        <h2 className={styles.update}>Delete Survey</h2>
        <p className={styles.msg}>Are you sure, you want to delete this?</p>
          <div className={styles.formdlt}>
          <Button sx={custom} disableElevation onClick={handledltClose} variant="contained">Cancel</Button>
          <Button sx={save} onClick={handleDelete} disableElevation variant="contained">Delete</Button>
      </div>
</Box>
      </Modal>
  )
}

export default DeleteSurvey;
