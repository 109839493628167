import React, { useEffect, useState } from 'react'
import { Arrow, Left, Right, Search, Vector, Edit, Delete, View, AddButton, MinusButton } from '../../shared/svg'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from './surveyforms.module.css'
import { Button } from '@mui/material';
import { custom, formselect, save, selectInput } from '../../materialUI/customStyle';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../helper/Api';
import { useFormik } from "formik";
import * as yup from "yup";

const AddSurvey = () => {
  const api = new Api();
  const navigate = useNavigate();
  const [allCategory, setAllCategory] = useState([])
  const [questions, setQuestions] = useState([
    {
      que: '',
      options: ['', '', '', '']
    }
  ])
  const fetchAllCategory = async () => {
    try {
      const { data, status } = await api.categoryDropdown();
      if (status === 200) {
        setAllCategory(data.data)
        // if(allCategory?.)
      }
    } catch (err) {
      console.log(err.response.data.message)
    }
  }
  const handleInput = (e) => {
    handleChange(e);
  }
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    status: yup.string().required("Staus is required"),
    category: yup.string().required("category\'s required")
  });

  const { errors, values, handleChange, setFieldValue, handleSubmit, setValues, touched, resetForm } = useFormik({
    initialValues: {
      name: '',
      category: '',
      status: '',
      type: 'default',
    },
    validationSchema: schema,
  });

  const handleAddSurvey = async (e) => {
    e.preventDefault();
    handleSubmit();
    const isError = errors.name || errors.status || errors.category;
    if (!isError) {
      try {

        if (values.type === "default") {
          const { data, status } = await api.addSurveyForm(values);
          if (status === 200) {
            toast.success('successfully added!')
            navigate('/survey/survey-forms')
          }
        } else {
          const { data, status } = await api.addSurveyForm({
            ...values,
            questions
          });
          if (status === 200) {
            toast.success('successfully added!')
            navigate('/survey/survey-forms')
          }
        }

      } catch (err) {
        toast(err.response.data.message)
      }
    }
  }

  useEffect(() => {
    fetchAllCategory();
  }, [])

  // add new question
  const addQuestion = () => {
    setQuestions([...questions,
    {
      que: '',
      options: ['', '', '', '']
    }
    ])
  }

  // remove question
  const removeQuestion = (index) => {
    // make a clone of question
    // const clonedQuestion = [...question];


    // remove question at particular nex
    // clonedQuestion.splice(i, 1)
    const removedQuestion = questions.filter((que, i) => i !== index)

    setQuestions(removedQuestion)
  }

  const editQuestion = (index, value) => {

    const clonedQuestion = [...questions];

    clonedQuestion[index].que = value;

    setQuestions(clonedQuestion);
  }

  const editOption = (index, optionIndex, value) => {
    const clonedAnswer = [...questions];
    clonedAnswer[index].options.splice(optionIndex, 1, value)

    setQuestions(clonedAnswer)
  }
  return (
    <div className='container'>
      <div className='list' style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Add New Survey form
          <Button onClick={() => navigate('/survey/survey-forms')} sx={save} disableElevation variant="contained">Back </Button>
        </div>
        <form className={styles.survey} style={{ padding: "2px 36px 2px 12px", gap: "25px" }}>
          <span>
            <label className={styles.title}>Survey Name</label>
            <input className={styles.inputbox} name='name' value={values.name} onChange={handleInput} type="text" placeholder='Enter' />
            {
              errors.name && touched.name && <p className={styles.error}>{errors.name}</p>
            }
          </span>
          <span>
            <label className={styles.title}>Category</label>

            <Select className={styles.formselect}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.category}
              sx={formselect}
              IconComponent={Arrow}
              displayEmpty
              onChange={handleInput}
              name='category'
            >
              <MenuItem value="">Select</MenuItem>
              {
                allCategory?.map((category) => {
                  return (<MenuItem key={category._id} value={category?._id}>{category?.name}</MenuItem>)
                })
              }
            </Select>
            {
              errors.category && touched.category && <p className={styles.error}>{errors.category}</p>
            }
          </span>
          <span>
            <label className={styles.title}>Status</label>
            <Select className={styles.formselect}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.status}
              sx={formselect}
              IconComponent={Arrow}
              displayEmpty
              onChange={handleInput}
              name='status'
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inActive'>Inactive</MenuItem>
            </Select>
            {
              errors.status && touched.status && <p className={styles.error}>{errors.status}</p>
            }
          </span>
          <span>
            <label className={styles.title}>Survey Type</label>
            <Select className={styles.formselect}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.type}
              sx={formselect}
              IconComponent={Arrow}
              displayEmpty
              onChange={handleInput}
              name='type'
            >

              <MenuItem value={'default'}>Default</MenuItem>
              <MenuItem value={'customize'}>Customize</MenuItem>
            </Select>
            {
              errors.type && touched.type && <p className={styles.error}>{errors.type}</p>
            }
          </span>
          {
            values.type === 'customize' &&
            <div className={`${styles.survey}`}>
              Questions
              <br /><br />
              {
                questions.map((que, i) => (
                  <>
                    <div className={styles.question}>
                      <span className={styles.longtext} style={{ marginTop: '10px', marginBottom: "20px", position: "relative" }}>
                        <label className={styles.title}>Enter Question {i + 1}</label>
                        <input className={styles.inputbox} type="text" value={que.que} onChange={(e) => editQuestion(i, e.target.value)} placeholder='Enter' />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', position: "absolute", right: "-45px", bottom: "-16px" }}>
                          {
                            i === 0 ?
                              <AddButton onClick={addQuestion} />
                              :
                              <MinusButton onClick={() => removeQuestion(i)} />
                          }
                        </div>
                      </span>
                    </div>
                    {
                      que?.options.map((option, index) => (
                        <span className={styles.option}>
                          <label className={styles.title}>Options {index + 1}</label>
                          <input className={styles.inputbox} value={option} type="text" onChange={(e) => editOption(i, index, e.target.value)} placeholder='Enter' />
                        </span>
                      ))
                    }
                  </>
                ))
              }
            </div>
          }


          <div className={styles.formbtn}>
            <Button sx={custom} onClick={() => navigate('/survey/survey-forms')} disableElevation variant="contained">Cancel</Button>
            <Button onClick={handleAddSurvey} sx={save} disableElevation variant="contained">Save</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddSurvey
