import React, { useEffect, useState } from 'react'
import { custom, formselect, save } from '../../materialUI/customStyle'
import { useNavigate } from 'react-router-dom'
import { Button, MenuItem, Select } from '@mui/material';
import styles from '../club/club.module.css'
import { Arrow } from '../../shared/svg';
import { styled } from '@mui/material/styles';
import Api from '../../helper/Api';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as yup from "yup";
import { MdDelete } from "react-icons/md";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const weeks = [
    {
        day: "SUN",
        fDay: "Sunday"
    },
    {
        day: "MON",
        fDay: "Monday"
    },
    {
        day: "TUE",
        fDay: "Tuesday"
    },
    {
        day: "WED",
        fDay: "Wednesday"
    },
    {
        day: "THU",
        fDay: "Thursday"
    },
    {
        day: "FRI",
        fDay: "Friday"
    },
    {
        day: "SAT",
        fDay: "Saturday"
    }
]

const AddVendor = () => {
    const [openHour, setOpenHour] = useState(false)
    const [closeHour, setCloseHour] = useState(false)
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const api = new Api();
    const navigate = useNavigate();

    const [allCategory, setAllCategory] = useState([]);
    const [allClub, setAllClub] = useState([{
        name: '', _id: ''
    }])
    const fetchAllCategory = async () => {
        try {
            const { data, status } = await api.categoryDropdown();
            if (status === 200) {
                setAllCategory(data.data)
            }
        } catch (err) {
            console.log(err.response.data.message)
        }
    }

    const fetchAllClub = async () => {
        try {
            const { data, status } = await api.fetchAllClubs();
            if (status === 200) {
                setAllClub(data.data)
            }
        } catch (err) {
            console.log(err.response.data.message)
        }
    }

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        status: yup.string().required("Staus is required"),
        category: yup.string().required("category is required"),
        club: yup.string().required("club/group is required"),
        workingDays: yup.array().required("working days are required"),
        workingHours: yup.array().required("working hours are required"),
        address: yup.string().required("address is required"),
        services: yup.string().required("services is required"),
        highlights: yup.string().required("highlights is required"),
        email: yup.string().email().required("email is required"),
        phone: yup.string().matches(/^\d{10}$/).required('phone number is required'),
        profileImg: yup.string().required('upload profile picture'),
        memberShip: yup.string().required("Membership is required"),
        started: yup.string().required("Service Start Date is required"),
        expireIn: yup.string().required("Service End Date is required")
    });

    const { errors, values, handleChange, setFieldValue, handleSubmit, setValues, touched, resetForm } = useFormik({
        initialValues: {
            category: '',
            club: '',
            status: '',
            workingDays: [],
            workingHours: [],
            memberShip: "free",
            started: "",
            expireIn: "",
            profileImg: "",
            name: '',
            phone: '',
            email: '',
            address: '',
            services: '',
            highlights: ''
        },
        validationSchema: schema,
        onSubmit: () => {
            handleAdd();
        }
    });

    useEffect(() => {
        fetchAllCategory();
        fetchAllClub();
    }, [])

    const handleInput = (e) => {
        handleChange(e)
    }
    const uploadImg = async (e) => {
        try {
            const formdata = new FormData();
            const file = e.target.files[0];
            formdata.append('image', file)
            const { data, status } = await api.uploadImage(formdata);
            if (status === 200) {
                const imgSrc = data.data[0]
                setValues({ ...values, 'profileImg': imgSrc })

            }
        } catch (err) {
            toast.error("Image upload failed")
        }
    }

    const handleAdd = async (e) => {
        // handleSubmit();
        const isError = errors.name || errors.status || errors.category || errors.club || errors.workingDays || errors.workingHours || errors.highlights || errors.services || errors.address || errors.email || errors.phone || errors.profileImg;
        if (!isError) {
            try {
                const { data, status } = await api.addNewVendor(values);
                if (status === 200) {
                    toast('Successfully Vendor Added!')
                    navigate('/vendor/profiles')
                }
            } catch (err) {
                console.log(err)
                toast.error(err.response.data.message)
            }
        }
    }

    const handleOpening = (date) => {
        let hour = date.$H;
        let minute = date.$m
        let format = "AM";

        if (hour > 12) {
            hour = hour - 12
            format = "PM"
        }

        const profileClone = { ...values }

        setFieldValue("workingHours", [`${hour}:${minute} ${format}`, profileClone["workingHours"][1]])

    };

    const handleClosing = (date) => {
        let hour = date.$H;
        let minute = date.$m
        let format = "AM";

        const profileClone = { ...values }

        if (hour > 12) {
            hour = hour - 12
            format = "PM"
        }

        setFieldValue("workingHours", [profileClone["workingHours"][0], `${hour}:${minute} ${format}`]);
    };
    // console.log(addVendor)
    return (
        <div className='container'>
            <div className='list' style={{ width: '100%', paddingBottom: '2px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    Add New Vendor Profile
                    <Button onClick={() => navigate('/vendor/profiles')} sx={save} disableElevation variant="contained">Back </Button>
                </div>
                <form className={styles.survey} style={{ padding: "2px 36px 2px 12px", gap: "20px" }}>
                    <span>
                        <label className={styles.title}>Vendor Name</label>
                        <input name='name' value={values.name} onChange={handleInput} className={styles.inputbox} type="text" placeholder='Enter' />
                        {
                            errors.name && touched.name && <p className={styles.error}>{errors.name}</p>
                        }
                    </span>
                    <span>
                        <label className={styles.title}>Club</label>

                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                            name='club' value={values.club} onChange={handleInput}
                        >
                            <MenuItem value="">Select</MenuItem>
                            {
                                allClub.map((club) => (
                                    <MenuItem key={club._id} value={club._id}>{club.name}</MenuItem>
                                ))
                            }
                        </Select>
                        {
                            errors.club && touched.club && <p className={styles.error}>{errors.club}</p>
                        }
                    </span>
                    <span>
                        <label className={styles.title}>Status</label>
                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='status' value={values.status} onChange={handleInput}
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value='active'>Active</MenuItem>
                            <MenuItem value='inActive'>Inactive</MenuItem>
                        </Select>
                        {
                            errors.status && touched.status && <p className={styles.error}>{errors.status}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Phone number</label>
                        <input name='phone' value={values.phone} onChange={handleInput} className={styles.inputbox} type="text" placeholder='Enter' />
                        {
                            errors.phone && touched.phone && <p className={styles.error}>{errors.phone}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Email address</label>
                        <input name='email' value={values.email} onChange={handleInput} className={styles.inputbox} type="text" placeholder='Enter' />
                        {
                            errors.email && touched.email && <p className={styles.error}>{errors.email}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Category</label>
                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='category' value={values.category} onChange={handleInput}
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                        >
                            <MenuItem value=''>Select</MenuItem>
                            {
                                allCategory.map((category) => (
                                    <MenuItem value={category?._id}>{category?.name}</MenuItem>
                                ))
                            }
                        </Select>
                        {
                            errors.category && touched.category && <p className={styles.error}>{errors.category}</p>
                        }
                    </span>

                    <span className={styles.longtext} >
                        <label className={styles.title}>Address</label>
                        <input name='address' value={values.address} onChange={handleInput} className={styles.inputbox} type="text" placeholder='Enter' />
                        {
                            errors.address && touched.address && <p className={styles.error}>{errors.address}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Opening days</label>
                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='workingDays' value={values.workingDays} onChange={handleInput}
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                            multiple
                        >
                            <MenuItem value="">Select</MenuItem>
                            {
                                weeks.map((week) => (
                                    <MenuItem value={week.day}>{week.fDay}</MenuItem>
                                ))
                            }
                        </Select>
                        {
                            errors.workingDays && touched.workingDays && <p className={styles.error}>{errors.workingDays}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Opening Hour</label>
                        <input name='name' value={values?.workingHours[0]} onClick={() => setOpenHour(true)} className={styles.inputbox} style={{ cursor: "pointer" }} type="text" placeholder='Enter' />
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", bottom: 15, right: 0 }} width="15" height="10" viewBox="0 0 13 8" fill="none">
                            <path d="M11.8296 1.375L6.57959 6.625L1.32959 1.375" stroke="#7879F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker style={{ border: 'none' }}
                                open={openHour}
                                onChange={(value) => handleOpening(value)}
                                onClose={() => {
                                    setOpenHour(false)
                                }}
                                closeOnSelect
                                sx={{ visibility: "hidden", margin: 0, padding: 0, height: 0 }}
                                defaultValue={dayjs(`2022-04-17T${values.workingHours[0]}`)}
                            />
                        </LocalizationProvider>
                        {
                            errors.workingHours && touched.workingHours && <p className={styles.error}>{errors.workingHours}</p>
                        }
                    </span>
                    <span className='gap' >
                        <label className={styles.title}>Closing Hour</label>
                        <input name='name' value={values?.workingHours[1]} onClick={() => setCloseHour(true)} className={styles.inputbox} style={{ cursor: "pointer" }} type="text" placeholder='Enter' />
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", bottom: 15, right: 0 }} width="15" height="10" viewBox="0 0 13 8" fill="none">
                            <path d="M11.8296 1.375L6.57959 6.625L1.32959 1.375" stroke="#7879F1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker style={{ border: 'none' }}
                                open={closeHour}
                                onChange={(value) => handleClosing(value)}
                                onClose={() => {
                                    setCloseHour(false)
                                }}
                                // onAccept={() => setOpen(false)}
                                closeOnSelect
                                sx={{ visibility: "hidden", margin: 0, padding: 0, height: 0 }}
                                defaultValue={dayjs(`2022-04-17T${values.workingHours[0]}`)}
                            />
                        </LocalizationProvider>
                        {
                            errors.workingHours && touched.workingHours && <p className={styles.error}>{errors.workingHours}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Membership</label>
                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='memberShip' value={values.memberShip} onChange={handleInput}
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select</MenuItem>
                            <MenuItem value="free">Free</MenuItem>
                            <MenuItem value="paid">Paid</MenuItem>
                        </Select>
                        {
                            errors.memberShip && touched.memberShip && <p className={styles.error}>{errors.memberShip}</p>
                        }
                    </span>

                    <span className='gap'>
                        <label className={styles.title}>Service Start Date</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker sx={formselect} onChange={(value) => setFieldValue("started", value.$d)} value={dayjs(values.started)} />
                        </LocalizationProvider>
                        {
                            errors.started && touched.started && <p className={styles.error}>{errors.started}</p>
                        }
                    </span>

                    <span className='gap'>
                        <label className={styles.title}>Service Expire Date</label>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker sx={formselect} onChange={(value) => setFieldValue("expireIn", value.$d)} value={dayjs(values.expireIn)} />
                        </LocalizationProvider>
                        {
                            errors.expireIn && touched.expireIn && <p className={styles.error}>{errors.expireIn}</p>
                        }
                    </span>

                    <span className={styles.longtext} >
                        <label className={styles.title}>Services</label>
                        <input name='services' value={values.services} onChange={handleInput} className={styles.inputbox} type="text" placeholder='Enter' />
                        {
                            errors.services && touched.services && <p className={styles.error}>{errors.services}</p>
                        }
                    </span>
                    <span className={styles.longtext} >
                        <label className={styles.title}>Highlights</label>
                        <input name='highlights' value={values.highlights} onChange={handleInput} className={styles.inputbox} type="text" placeholder='Enter' />
                        {
                            errors.highlights && touched.highlights && <p className={styles.error}>{errors.highlights}</p>
                        }
                    </span>
                    <span className='gap'>
                        <label className={styles.title}>Upload Profile Image</label>
                        <div className={styles.border}>
                            {
                                values.profileImg ? <MdDelete className={styles.dltImg} onClick={() => setFieldValue('profileImg', '')} /> : ''
                            }
                            {
                                values.profileImg ? <img src={values.profileImg} height={100} width={200} style={{ objectFit: "contain", borderRadius: 5 }} />
                                    :
                                    <Button sx={save} component="label" name='profileImg' onChange={(e) => uploadImg(e, 'profileImg')} disableElevation variant="contained">upload
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                            }
                        </div>
                        <p className={styles.info} style={{ marginBottom: '15px' }}>Image size 300px*159px</p>
                        {
                            errors.profileImg && touched.profileImg && <p className={styles.error}>{errors.profileImg}</p>
                        }
                    </span>
                    <div className={styles.formbtn}>
                        <Button sx={custom} onClick={() => navigate('/vendor/profiles')} disableElevation variant="contained">Cancel</Button>
                        <Button sx={save} onClick={handleSubmit} disableElevation variant="contained">Save</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddVendor;
