import React, { useEffect, useState } from 'react'
import { Arrow, Delete, Edit, Left, Right, Search, Vector } from '../../shared/svg'
import styles from './category.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { selectInput, custom, save, formselect } from '../../materialUI/customStyle';
import Button from '@mui/material/Button';
import EditCategory from '../../components/EditCategory';
import DeleteCategory from '../../components/DeleteCategory';
import { useFormik } from "formik";
import * as yup from "yup";
import Api from '../../helper/Api';
import { toast } from 'react-toastify';

import sortTable from '../../helper/sortTable';
import ascIcon from "../../Assets/asc.svg"
import dscIcon from "../../Assets/des.svg"
import defaultIcon from "../../Assets/none.svg"


const Category = () => {
  const api = new Api();
  const [categoryList, setCategoryList] = useState([]);
  const [statuss, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [categoryToEdit, setCateogoryToEdit] = useState(null)
  const [categoryToDelete, setCateogoryToDelete] = useState(null)
  const [sortBy, setSortBy] = useState("")
  const [attribute, setAttribute] = useState("")
  const [isDigit, setIsDigit] = useState(false)


  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false);

  const handleOpen = (category) => {
    setCateogoryToEdit(category)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = (category) => {
    setCateogoryToDelete(category)
    setdltOpen(true)
  };
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }
  const handledltClose = () => setdltOpen(false);
  const fetchList = async () => {
    try {
      const { data, status } = await api.fetchCategory({}, { statuss, search, page });
      if (status === 200) {
        setCategoryList(data.data)
        setTotalPages(data.totalPages)
      }
    } catch (err) {
      console.log(err, "error")
    }
  }

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    status: yup.string().required("Staus is required"),
  });

  const addCategory = async (e) => {
    e.preventDefault();
    handleSubmit();
    const isError = errors.name || errors.status;
    if (!isError) {
      try {
        const { data, status } = await api.addCategory(values);
        if (status === 200) {
          toast('Added successfully')
          resetForm();
          setRefresh(!refresh)
        }
      } catch (err) {
        toast.error(err.response.data.message)
      }
    }
  }
  const { errors, values, handleChange, setFieldValue, handleSubmit, setValues, touched, resetForm } = useFormik({
    initialValues: {
      name: "",
      status: "",
    },
    validationSchema: schema,
  });

  const cancel = () => {
    resetForm();
  }
  useEffect(() => {
    fetchList();
  }, [refresh, statuss, search, page])

  const handleSort = (isDigit, attr) => {
    if (attr === attribute) {

      if (sortBy === "asc") {
        setSortBy("dsc")
      } else if (sortBy === "dsc") {
        setSortBy("")
      } else {
        setSortBy("asc")
      }

      setAttribute(attr)
      setIsDigit(isDigit)
    } else {
      // setting default value
      setAttribute(attr)
      setIsDigit(isDigit)
      setSortBy("asc")
    }
  }

  return (
    <div className='container'>
      <EditCategory handleClose={handleClose} open={open} category={categoryToEdit} refresh={refresh} setRefresh={setRefresh} />
      <DeleteCategory handledltClose={handledltClose} dltOpen={dltOpen} category={categoryToDelete} refresh={refresh} setRefresh={setRefresh} />
      <div className='list' style={{ width: 'calc(100% - 42%)' }}>
        Form Category
        <br />
        <div style={{ display: 'flex', marginTop: '27px' }}>
          <Select className={styles.selectinput}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statuss}
            sx={selectInput}
            IconComponent={Arrow}
            displayEmpty
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='inActive'>Inactive</MenuItem>
          </Select>
          <div className={styles.search}><Search /> <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
        </div>
        <div className={styles.table}>
          <div className={styles.head}>
            <p style={{ width: '15%', display: 'flex', alignItems: 'center' }}>Sl No </p>
            <p style={{ width: '49%', display: 'flex', alignItems: 'center' }}>Category Name <img onClick={() => handleSort(false, "name")} src={attribute === "name" && sortBy === "asc" ? ascIcon : attribute === "name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '20%', display: 'flex', alignItems: 'center' }}>Status <img onClick={() => handleSort(false, "status")} src={attribute === "status" && sortBy === "asc" ? ascIcon : attribute === "status" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '16%' }}>Options</p>
          </div>
          {
            sortTable(categoryList, attribute, isDigit, sortBy).map((category, index) => (
              <div key={index} className={styles.list}>
                <p style={{ width: '15%' }}>{index + 1}</p>
                <p style={{ width: '49%' }}>{category.name}</p>
                <p style={{ width: '20%' }}><span className={category.status === 'active' ? '' : `${styles.inactive}`} style={{ width: "70px", textAlign: "center", display: "inline-block" }}>{category.status === 'active' ? 'Active' : "Inactive"}</span></p>
                <p style={{ width: '16%', display: 'flex', alignItems: 'center', gap: '9px' }}><Edit handleOpen={() => handleOpen(category)} /> <Delete handledltOpen={() => handledltOpen(category)} /></p>
              </div>
            ))
          }
          <div className={styles.page}>
            Page <Left decrement={decrement} /> <div>{page}</div> <Right increment={increment} />
          </div>
        </div>
      </div>
      <div className='list' style={{ width: '40%' }}>
        Add New category
        <form className={styles.form}>
          <label>Category Name</label>
          <input type="text" placeholder='Enter' name='name' value={values.name} onChange={handleChange} />
          {
            errors.name && touched.name && <p className={styles.error}>{errors.name}</p>
          }
          <br /><br />
          <label>Status</label>

          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.status}
            name='status'
            sx={formselect}
            IconComponent={Arrow}
            displayEmpty
            onChange={handleChange}
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='inActive'>InActive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p className={styles.error}>{errors.status}</p>
          }
          <div className={styles.formbtn}>
            <Button onClick={cancel} sx={custom} disableElevation variant="contained">Cancel</Button>
            <Button onClick={addCategory} sx={save} disableElevation variant="contained">Save</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Category
