import React from 'react'

const Loader = () => {
    return (
        <div>
            <div className="loadingio-spinner-spinner-ce0myzlaoj5"><div className="ldio-8u7kjjo29ur">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div></div>
        </div>
    )
}

export default Loader