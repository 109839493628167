function getNestedValue(obj, path) {
    const keys = path.split('.');
    let nestedValue = obj;

    for (const key of keys) {
        if (nestedValue && nestedValue.hasOwnProperty(key)) {
            nestedValue = nestedValue[key];
        } else {
            // Handle the case where the key is not found
            console.error(`Key "${key}" not found in path "${path}".`);
            nestedValue = "";
            break;
        }
    }

    return nestedValue;
}


const sortTable = (array, attribtute, isDigit, sortBy) => {

    const clonedArray = [...array];

    if (isDigit) {
        if (sortBy === "asc") {
            // ascending order
            return clonedArray.sort((a, b) => {
                return a[attribtute] - b[attribtute];
            })
        } else if (sortBy === "dsc") {
            return clonedArray.sort((a, b) => {
                return b[attribtute] - a[attribtute];
            })
        } else {
            return clonedArray
        }
    } else {
        if (sortBy === "asc") {
            // ascending order
            return clonedArray.sort((a, b) => {
                let stringA = getNestedValue(a, attribtute).toLowerCase();
                let stringB = getNestedValue(b, attribtute).toLowerCase();

                return stringA.localeCompare(stringB);
            })
        } else if (sortBy === "dsc") {
            return clonedArray.sort((a, b) => {
                let stringA = getNestedValue(a, attribtute).toLowerCase();
                let stringB = getNestedValue(b, attribtute).toLowerCase();

                return stringB.localeCompare(stringA);
            })
        } else {
            return clonedArray
        }
    }
}

export default sortTable