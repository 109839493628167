import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './css.module.css';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, save, formselect } from '../materialUI/customStyle';
import { Arrow } from '../shared/svg';
import { toast } from 'react-toastify';
import Api from '../helper/Api';
import * as yup from "yup";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "29px 22px",
  height: "fit-content",
  display: "block",
  width: '450px',
  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const EditCategory = ({ handleClose, open, category, refresh, setRefresh }) => {
  //console.log(category)
  const api = new Api();
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    status: yup.string().required("Staus is required"),
    _id: yup.string()
  });
  const handleEdit = async (e) => {
    e.preventDefault();
    handleSubmit();
    const isError = errors.name || errors.status;
    if(!isError){
    try {
      const { data, status } = await api.editCategory(values);
      console.log('erro1111')
      if (status === 200) {
        toast('Edit category!')
        handleClose();
        setRefresh(!refresh)
      }
    } catch (err) {
      console.log('erro')
      toast(err.response.data.message)
    }
  }
  }
  const {errors, values, handleChange, setFieldValue, handleSubmit, setValues, touched, resetForm} = useFormik({
    initialValues: {
      name: "",
      status: "",
      _id: ''
    },
    validationSchema: schema,
  });
  
  useEffect(() => {
    if (category) {
      setValues({
        _id: category?._id,
        name: category?.name,
        status: category?.status
      })
    }
  }, [category])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2 className={styles.update}>Edit Category</h2>
        <form className={styles.form}>
          <label>Category Name</label>
          <input type="text" placeholder='Enter' value={values.name} name='name' onChange={handleChange} />
          {
            errors.name && touched.name && <p className={styles.error}>{errors.name}</p>
          }
          <br /><br />
          <label>Status</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.status}
            sx={formselect}
            IconComponent={Arrow}
            displayEmpty
            name='status'
            onChange={handleChange}
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inActive">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p className={styles.error}>{errors.status}</p>
          }
          <div className={styles.formbtn}>
            <Button sx={custom} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
            <Button sx={save} onClick={handleEdit} disableElevation variant="contained">Update</Button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default EditCategory;

