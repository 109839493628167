import React, { useEffect, useState } from 'react'
import { Arrow, Left, Right, Search, Vector, Edit, Delete, View, AddButton, MinusButton } from '../../shared/svg'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from './surveyforms.module.css'
import { Button } from '@mui/material';
import { custom, formselect, save, selectInput } from '../../materialUI/customStyle';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../helper/Api';
import { useFormik } from "formik";
import * as yup from "yup";

const ViewForm = () => {
    const api = new Api();
    const navigate = useNavigate();
    const { reportId, id } = useParams();
    const [page, setPage] = useState(1)

    const [questions, setQuestions] = useState([
        {
            que: '',
            options: ['', '', '', '']
        }
    ])

    const getPrev = async () => {
        try {
            const { data, status } = await api.singleSurvey(id);
            if (status === 200) {
                const { name, category, status, type, questions } = data.data
                setValues({ name, status, type, category: category })
                if (type === 'customize') {
                    setQuestions(questions)
                }
            }
        } catch (err) {
            console.log(err?.response?.data.message)
        }
    }

    useEffect(() => {
        getPrev();
    }, [id])

    const handleInput = () => {

    }
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        status: yup.string().required("Staus is required"),
        category: yup.string().required("category\'s required")
    });

    const { values, setValues } = useFormik({
        initialValues: {
            name: '',
            category: '',
            status: '',
            type: 'default',
        },
        validationSchema: schema,
    });

    return (
        <div className='container'>
            <div className='list' style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    Survey form
                    <Button onClick={() => navigate('/survey/survey-forms')} sx={save} disableElevation variant="contained">Back </Button>
                </div>
                <form className={styles.survey} style={{ padding: "2px 36px 2px 12px", gap: "25px" }}>
                    <span>
                        <label className={styles.title}>Survey Name</label>
                        <input className={styles.inputbox} name='name' value={values.name} onChange={handleInput} type="text" placeholder='Enter' />
                    </span>
                    <span>
                        <label className={styles.title}>Category</label>
                        <input className={styles.inputbox} name='category' value={values.category?.name} onChange={handleInput} type="text" placeholder='Enter' />
                    </span>
                    <span>
                        <label className={styles.title}>Status</label>
                        <input className={styles.inputbox} name='status' value={values.status} onChange={handleInput} type="text" placeholder='Enter' />
                    </span>
                    <span>
                        <label className={styles.title}>Survey Type</label>
                        <input className={styles.inputbox} name='status' value={values.type} onChange={handleInput} type="text" placeholder='Enter' />
                    </span>
                    {
                        values.type === 'customize' &&
                        <div className={`${styles.survey}`}>
                            Questions
                            <br /><br />
                            {
                                questions.map((que, i) => (
                                    <>
                                        <div className={styles.question}>
                                            <span className={styles.longtext}>
                                                <label className={styles.title}>Enter Question {i + 1}</label>
                                                <input className={styles.inputbox} type="text" value={que.que} onChange={() => { }} placeholder='Enter' />
                                            </span>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>

                                            </div>
                                        </div>
                                        {
                                            que?.options.map((option, index) => (
                                                <span className={styles.option}>
                                                    <label className={styles.title}>Options {index + 1}</label>
                                                    <input className={styles.inputbox} value={option} type="text" onChange={() => { }} placeholder='Enter' />
                                                </span>
                                            ))
                                        }
                                    </>
                                ))
                            }
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}

export default ViewForm
