import React, { useEffect, useState } from 'react'
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Video from './Video';
import Slider from "react-slick";
import Picker from 'emoji-picker-react';
import { Send, Attechment, Emoji } from '../shared/svg';
import styles from "../pages/club/club.module.css"
import Api from '../helper/Api';
import { toast } from 'react-toastify';
import { MdDelete } from "react-icons/md";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    padding: "27px 22px",
    height: "fit-content",
    display: "block",
    width: '372px',
    "&:focus": {
        outline: "none",
    },
};

const EditPost = ({ handleClose, open, data, setLoading, setReFetch, reFetch }) => {
    const api = new Api();
    const [fileResponse, setFileResponse] = useState([]);
    const [openEmoji, setOpenEmoji] = useState(false)
    const [description, setDescription] = useState("");
    const [assets, setAssets] = useState([]);

    const id = data?._id;

    useEffect(() => {
        if (data) {
            setDescription(data.description)
            setAssets(data.assets)
        }
    }, [data])

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        customPaging: function (i) {
            return <div className="dot"></div>;
        },
        dotsClass: "slick-dots slick-thumb",
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const onEmojiClick = (emojiData) => {
        setDescription(`${description}${emojiData.emoji}`)
    };

    const handlePost = async () => {
        try {
            setLoading(true)

            // check if all fields are filled
            if (!description) {
                toast.error("Please write some description")
            }
            else {
                // upload images
                let assetsData = []
                for (let i = 0; i < fileResponse.length; i++) {
                    const formData = new FormData();
                    formData.append("image", fileResponse[i])
                    const { data, status } = await api.uploadImage(formData)

                    if (status === 200) {
                        assetsData.push({
                            src: data.data[0],
                            type: fileResponse[i].type === "video/mp4" ? "video" : "img"
                        })
                    }
                }

                const { data, status } = await api.editPost({
                    description,
                    assets: [...assets, ...assetsData]
                }, { id })

                if (status === 200) {
                    setLoading(false)
                    setReFetch(!reFetch)
                    setDescription("")
                    setFileResponse([])
                    handleClose();
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const handleEmoji = async () => {
        setOpenEmoji(!openEmoji)
    }

    const removeAsset = (index, type) => {
        if (type === "file") {
            let fileClone = [...fileResponse]
            fileClone.splice(index, 1)

            setFileResponse(fileClone);
        } else {
            let assetsClone = [...assets]
            assetsClone.splice(index, 1)

            setAssets(assetsClone)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <p className={styles.timeline}>Edit Post</p>
                <hr className={styles.line} />
                <div className={styles.commentbox}>
                    <textarea className={styles.comment} onChange={(e) => setDescription(e.target.value)} value={description} placeholder='Start from here' name="comment" cols="30" rows="10"></textarea>

                    <div className={styles.attech}>
                        {/* <Mark /> */}
                        <Emoji onClick={handleEmoji} />
                        <input type='file' id='files2' multiple accept='image/png, image/jpeg, video/mp4' component="label" name='files' style={{ visibility: 'hidden', display: "none" }} onChange={(e) => setFileResponse([...e.target.files])} disableElevation variant="contained">
                            {/* <VisuallyHiddenInput type="file" multiple /> */}
                        </input>
                        <label htmlFor="files2"><Attechment /> </label>
                        <span onClick={handlePost} style={{ cursor: "pointer" }}>Post <Send /></span>
                    </div>
                    {
                        openEmoji &&
                        <Picker onEmojiClick={onEmojiClick} style={{ width: "100%", marginTop: 10 }} />
                    }

                    <Slider {...settings} className='slider'>
                        {
                            assets?.map((asset, i) => {
                                return (
                                    <div style={{ border: "none", outline: "none", position: "relative" }}>
                                        <div onClick={() => removeAsset(i, "assets")} style={{ position: "absolute", zIndex: 5, cursor: "pointer", width: 28, margin: 5, height: 28, display: "flex", alignItems: "center", justifyContent: "center", background: "#fff", borderRadius: "50%" }}>
                                            <MdDelete fontSize={20} color='#000' />
                                        </div>
                                        {
                                            asset?.type == 'video' ? <Video src={asset.src} mute={true} setMute={() => { }} width={"100%"} height={'auto'} style={{ aspectRatio: 1.8 }} mt={0} /> : <img key={i} src={asset.src} style={{ width: "100%", height: "auto", aspectRatio: 1.8, objectFit: "cover" }} />
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            fileResponse.map((asset, i) => {
                                return (
                                    <div style={{ border: "none", outline: "none" }}>
                                        <div onClick={() => removeAsset(i, "file")} style={{ position: "absolute", zIndex: 5, cursor: "pointer", width: 28, margin: 5, height: 28, display: "flex", alignItems: "center", justifyContent: "center", background: "#fff", borderRadius: "50%" }}>
                                            <MdDelete fontSize={20} color='#000' />
                                        </div>
                                        {
                                            asset?.type === 'video/mp4' ? <Video src={URL.createObjectURL(asset)} mute={true} setMute={() => { }} width={"100%"} height={'auto'} style={{ aspectRatio: 1.8 }} mt={0} /> : <img key={i} src={URL.createObjectURL(asset)} style={{ width: "100%", height: "auto", aspectRatio: 1.8, objectFit: "cover" }} />
                                        }
                                    </div>
                                )
                            })
                        }

                    </Slider>
                </div>
            </Box>
        </Modal>
    )
}

export default EditPost