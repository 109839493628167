import React from 'react'
import styles from './header.module.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import avatar from "../../Assets/Pic.png"

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token')
    toast('Logged out Successfully')
    setTimeout(() => {
      navigate('/')
    }, 2500)
  }
  return (
    <div className={styles.header}>
      <div className={styles.profile}>
        <img src={avatar} className={styles.admin} />
        <p>Admin</p>
      </div>
      <p className={styles.logout} onClick={handleLogout}>logout</p>
    </div>
  )
}

export default Header
