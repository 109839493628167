import React, { useEffect, useState } from 'react'
import styles from './club.module.css'
import Api from '../../helper/Api';
import { useParams } from 'react-router-dom';

const Vendors = ({ data }) => {
  return (
    <div className={styles.vendor_container}>
      {
        data.length === 0
          ? <div>
            <h4 style={{ textAlign: "center", paddingTop: 100, fontWeight: 500, fontSize: 22 }}>No vendor found</h4>
          </div>
          : data.map((vendor) => {
            return (
              <div key={vendor?._id} className={styles.user}>
                <img src={vendor?.profileImg} className={styles.dp} />
                <div>
                  <p className={styles.username}>{vendor?.name}</p>
                  <p className={styles.abt}>{vendor?.category?.name}</p>
                </div>
              </div>
            )
          })
      }

    </div>
  )
}

export default Vendors
