export function timeAgo(createdAt) {
    const currentDate = new Date();
    const postDate = new Date(createdAt);

    const timeDifference = currentDate - postDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
        return years + (years === 1 ? 'y' : 'y');
    } else if (months > 0) {
        return months + (months === 1 ? 'm' : 'm');
    } else if (days > 0) {
        return days + (days === 1 ? 'd' : 'd');
    } else if (hours > 0) {
        return hours + (hours === 1 ? 'h' : 'h');
    } else if (minutes > 0) {
        return minutes + (minutes === 1 ? 'm' : 'm');
    } else {
        return seconds + (seconds === 1 ? 's' : 's');
    }
}