import React, { useState, useEffect } from 'react'
import { Arrow } from '../../shared/svg'
import styles from './club.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, save, formselect } from '../../materialUI/customStyle';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Api from '../../helper/Api';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as yup from "yup";
import { MdDelete } from "react-icons/md";

const AddClub = () => {
    const api = new Api();
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState([{
        name: '', _id: ''
    }]);
    const fetchAllCategory = async () => {
        try {
            const { data, status } = await api.categoryDropdown();
            if (status === 200) {
                setAllCategory(data.data)
            }
        } catch (err) {
            console.log(err.response.data.message)
        }
    }

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        status: yup.string().required("Staus is required"),
        category: yup.array().min(1, "Select atleast 1 category").required("Category is required"),
        description: yup.string().required("description is required"),
        profile: yup.string().required('upload profile picture'),
        coverImg: yup.string().required('upload cover image picture')
    });

    const { errors, values, handleChange, setFieldValue, handleSubmit, setValues, touched, resetForm } = useFormik({
        initialValues: {
            name: '',
            category: [],
            status: '',
            description: '',
            coverImg: "",
            profile: "",
        },
        validationSchema: schema,
        onSubmit: () => {
            handleAdd();
        }
    });

    const handleAdd = async () => {
        const isError = errors.name || errors.status || errors.category || errors.description || errors.coverImg || errors.profile;
        if (!isError) {
            try {
                const { data, status } = await api.addClub(values);
                if (status === 200) {
                    toast('Successfully added new Club/group')
                    navigate('/vendor/club')
                }
            } catch (err) {
                toast.error(err.response.data.message)
            }
        }
    }
    useEffect(() => {
        fetchAllCategory();
    }, [])


    const uploadImg = async (e, name) => {
        try {

            const formData = new FormData();
            const file = e.target.files[0];


            formData.append("image", file)

            const { data, status } = await api.uploadImage(formData)

            if (status === 200) {
                const imgSrc = data.data[0];

                if (name === "profile") {
                    setValues({ ...values, profile: imgSrc })
                }
                else {
                    setValues({ ...values, coverImg: imgSrc })
                }
            }
        } catch (err) {
            toast.error("Image upload failed")
        }
    }


    return (
        <div className='container'>
            <div className='list' style={{ width: '100%', paddingBottom: '2px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    Create New Club/Group
                    <Button onClick={() => navigate('/vendor/club')} sx={save} disableElevation variant="contained">Back </Button>
                </div>
                <form className={styles.survey} style={{ padding: "2px 36px 2px 12px" }}>
                    <span>
                        <label className={styles.title}>Club/Group Name</label>
                        <input className={styles.inputbox} name='name' value={values.name} type="text" onChange={handleChange} placeholder='Enter' />
                        {
                            errors.name && touched.name && <p className={styles.error}>{errors.name}</p>
                        }
                    </span>
                    <span>
                        <label className={styles.title}>Category</label>

                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.category}
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                            name='category'
                            multiple
                            placeholder='Select'
                            onChange={handleChange}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <p>Select</p>;
                                }

                                return selected.map((item, i) => {
                                    return allCategory.find((category) => category._id === item).name
                                }).join(", ")
                            }}
                        >
                            <MenuItem value="" defaultChecked disabled>Select</MenuItem>
                            {allCategory.map((cate) => (
                                <MenuItem value={cate._id}>{cate.name}</MenuItem>
                            ))}
                        </Select>
                        {
                            errors.category && touched.category && <p className={styles.error}>{errors.category}</p>
                        }
                    </span>
                    <span>
                        <label className={styles.title}>Status</label>
                        <Select className={styles.formselect}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.status}
                            sx={formselect}
                            IconComponent={Arrow}
                            displayEmpty
                            name='status'
                            onChange={handleChange}
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value='active'>Active</MenuItem>
                            <MenuItem value='inActive'>Inactive</MenuItem>
                        </Select>
                        {
                            errors.status && touched.status && <p className={styles.error}>{errors.status}</p>
                        }
                    </span>
                    <span className={styles.longtext} style={{ marginTop: '25px' }}>
                        <label className={styles.title}>Description</label>
                        <input name='description' value={values.description} className={styles.inputbox} onChange={handleChange} type="text" placeholder='Enter' />
                        {
                            errors.description && touched.description && <p className={styles.error}>{errors.description}</p>
                        }
                    </span>
                    <div style={{ display: 'flex', marginTop: '25px', gap: '100px' }}>
                        <span>
                            <label className={styles.title}>Upload cover image</label>
                            <div className={styles.border}>
                                {
                                    values.coverImg ? <MdDelete className={styles.dltImg} onClick={() => setFieldValue('coverImg', '')} /> : ''
                                }
                                {
                                    values.coverImg ? <img width={200} height={100} src={values.coverImg} alt="" />
                                        :
                                        <Button sx={save} component="label" disableElevation variant="contained">upload
                                            <VisuallyHiddenInput type="file" onChange={(e) => uploadImg(e, "coverImg")} />
                                        </Button>
                                }
                            </div>
                            <p className={styles.info} style={{ marginBottom: '15px' }}>Image size 300px*159px</p>
                            {
                                errors.coverImg && touched.coverImg && <p className={styles.error}>{errors.coverImg}</p>
                            }
                        </span>

                        <span>
                            <label className={styles.title}>Upload Profile image</label>
                            <div className={styles.border}>
                                {
                                    values.profile ? <MdDelete className={styles.dltImg} onClick={() => setFieldValue('profile', '')} /> : ''
                                }
                                {
                                    values.profile ? <img width={200} height={100} src={values.profile} alt="" />
                                        :
                                        <Button sx={save} component="label" disableElevation variant="contained">upload
                                            <VisuallyHiddenInput type="file" onChange={(e) => uploadImg(e, "profile")} />
                                        </Button>
                                }
                            </div>
                            <p className={styles.info} style={{ marginBottom: '15px' }}>Image size 300px*159px</p>
                            {
                                errors.profile && touched.profile && <p className={styles.error}>{errors.profile}</p>
                            }
                        </span>
                    </div>
                    <div className={styles.formbtn}>
                        <Button sx={custom} onClick={() => navigate('/vendor/club')} disableElevation variant="contained">Cancel</Button>
                        <Button sx={save} onClick={handleSubmit} disableElevation variant="contained">Save</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddClub
