import React, { useEffect, useState } from 'react'
import { Arrow, Delete, Edit, Left, Right, Search, Vector, View } from '../../shared/svg'
import styles from './club.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { selectInput, custom, save, formselect } from '../../materialUI/customStyle';
import Button from '@mui/material/Button';
import EditCategory from '../../components/EditCategory';
import DeleteCategory from '../../components/DeleteCategory';
import { useNavigate } from 'react-router-dom';
import Api from '../../helper/Api';
import DeleteClub from '../../components/DeleteClub';
import sortTable from '../../helper/sortTable';
import ascIcon from "../../Assets/asc.svg"
import dscIcon from "../../Assets/des.svg"
import defaultIcon from "../../Assets/none.svg"

const Club = () => {
  const api = new Api();
  const [deleteclub, setDeleteclub] = useState();
  const [refresh, setRefresh] = useState(false)
  const [statuss, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sortBy, setSortBy] = useState("")
  const [attribute, setAttribute] = useState("")
  const [isDigit, setIsDigit] = useState(false)
  const [clubs, setClubs] = useState([{
    name: '',
    status: '',
    category: [],
    totalPost: '',
    totalVendor: '',
    totalFollowers: ''

  }])
  const handleOpen = (id) => {
    navigate(`/vendor/club/edit-club/${id}`)
  }

  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = (id) => {
    setDeleteclub(id)
    setdltOpen(true)
  };
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }
  const handledltClose = () => setdltOpen(false);
  const navigate = useNavigate();
  const fetchClubs = async () => {
    try {
      const { data, status } = await api.allClubs({}, { statuss, search, page });
      if (status === 200) {
        setClubs(data.data);
        setTotalPages(data.totalPages)
      }
    } catch (err) {
      console.log(err.response.data.message)
    }
  }
  useEffect(() => {
    fetchClubs();
  }, [refresh, statuss, search, page])  // console.log(clubs)

  const handleSort = (isDigit, attr) => {
    if (attr === attribute) {

      if (sortBy === "asc") {
        setSortBy("dsc")
      } else if (sortBy === "dsc") {
        setSortBy("")
      } else {
        setSortBy("asc")
      }

      setAttribute(attr)
      setIsDigit(isDigit)
    } else {
      // setting default value
      setAttribute(attr)
      setIsDigit(isDigit)
      setSortBy("asc")
    }
  }
  return (
    <div className='container'>
      <DeleteClub handledltClose={handledltClose} dltOpen={dltOpen} deleteclub={deleteclub} refresh={refresh} setRefresh={setRefresh} />
      <div className='list' style={{ width: 'calc(100%)' }}>
        Clubs/Groups
        <br />
        <div className={styles.survey}>
          <div style={{ display: 'flex' }}>
            <Select className={styles.selectinput}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statuss}
              sx={selectInput}
              IconComponent={Arrow}
              displayEmpty
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="">status</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inActive">Inactive</MenuItem>
            </Select>
            <div className={styles.search}><Search /> <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
          </div>
          <Button onClick={() => navigate('/vendor/club/add-club')} sx={{ ...save, width: "fit-content" }} disableElevation variant="contained">Add new Club </Button>
        </div>
        <div className={styles.table}>
          <div className={styles.head}>
            <p style={{ width: '10%', display: 'flex', alignItems: 'center' }}>Sl No </p>
            <p style={{ width: '23%', display: 'flex', alignItems: 'center' }}>Club/Group Name <img onClick={() => handleSort(false, "name")} src={attribute === "name" && sortBy === "asc" ? ascIcon : attribute === "name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '15%', display: 'flex', alignItems: 'center' }}>Vendors  <img onClick={() => handleSort(true, "totalVendor")} src={attribute === "totalVendor" && sortBy === "asc" ? ascIcon : attribute === "totalVendor" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '10%', display: 'flex', alignItems: 'center' }}>Post <img onClick={() => handleSort(true, "totalPost")} src={attribute === "totalPost" && sortBy === "asc" ? ascIcon : attribute === "totalPost" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '15%', display: 'flex', alignItems: 'center' }}>Insights </p>
            <p style={{ width: '14%', display: 'flex', alignItems: 'center' }}>Status <img onClick={() => handleSort(false, "status")} src={attribute === "status" && sortBy === "asc" ? ascIcon : attribute === "status" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '13%' }}>Options</p>
          </div>
          {/* comment */}


          {
            sortTable(clubs, attribute, isDigit, sortBy).map((club, index) => {
              return (<div key={club._id} className={styles.list}>
                <p style={{ width: '10%' }}>{index + 1}</p>
                <p style={{ width: '23%' }}>{club?.name}</p>
                <p style={{ width: '15%' }}>{club.totalVendor}</p>
                <p style={{ width: '10%' }}>{club.totalPost}</p>
                <div style={{ width: '15%' }}><Button onClick={() => navigate(`/vendor/club/view-club/${club._id}`)} sx={save} disableElevation variant="contained">View </Button></div>
                <p style={{ width: '14%' }}><span className={club.status === 'inActive' ? `${styles.inactive}` : ''} style={{ width: "70px", textAlign: "center", display: "inline-block" }}>{club.status === 'active' ? 'Active' : "Inactive"}</span></p>
                <p style={{ width: '13%', display: 'flex', alignItems: 'center', gap: '9px' }}>
                  <View handleView={() => navigate(`/vendor/club/${club._id}`)} />
                  <Edit handleOpen={() => handleOpen(club._id)} />
                  <Delete handledltOpen={() => handledltOpen(club._id)} />
                </p>
              </div>)
            })
          }
          <div className={styles.page}>
            Page <Left decrement={decrement} /> <div>{page}</div> <Right increment={increment} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Club
