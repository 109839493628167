import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./shared/layout/Layout";
import './global.css'
import Notification from "./pages/notification/Notification.js";
import Login from './pages/login/Login';
import SurveyForms from "./pages/surveyforms/SurveyForms.js";
import AddSurvey from "./pages/surveyforms/AddSurvey.js";
import Club from "./pages/club/Club.js";
import Profiles from "./pages/profiles/Profiles.js";
import Category from "./pages/category/Category.js";
import AddClub from './pages/club/AddClub';
import ClubView from "./pages/club/ClubView.js";
import Vendors from "./pages/club/Vendors.js";
import AddVendor from "./pages/profiles/AddVendor.js";
import ProfileView from "./pages/profiles/ProfileView.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditSurvey from "./pages/surveyforms/EditSurvey.js";
import Editclub from './pages/club/Editclub';
import EditVendor from "./pages/profiles/EditVendor.js";
import Dashboard from "./pages/Dashboard/Dashboard.js";
import ClubDetail from "./pages/club/ClubDetail.js";
import ViewForm from "./pages/surveyforms/ViewForm.jsx";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={2500} />
      <Layout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/survey/category" element={<Category />} />
          <Route path="/survey/survey-forms" element={<SurveyForms />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/survey/survey-forms/add-survey" element={<AddSurvey />} />
          <Route path="/survey/survey-forms/view-survey/:id" element={<ViewForm />} />
          <Route path="/vendor/club" element={<Club />} />
          <Route path="/vendor/profiles" element={<Profiles />} />
          <Route path="/vendor/club/add-club" element={<AddClub />} />
          <Route path="/vendor/club/view-club/:id" element={<ClubView />} />
          <Route path="/vendor/club/view-club/vendors" element={<Vendors />} />
          <Route path="/vendor/profiles/add-vendor" element={<AddVendor />} />
          <Route path="/vendor/profiles/profile-view" element={<ProfileView />} />
          <Route path="/survey/survey-forms/edit-survey/:id" element={<EditSurvey />} />
          <Route path="/vendor/club/edit-club/:id" element={<Editclub />} />
          <Route path="/vendor/club/:id" element={<ClubDetail />} />
          <Route path="/vendor/profiles/edit-vendor/:id" element={<EditVendor />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
