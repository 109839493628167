import axios from "axios";

let token = '';

export default class Api {
    constructor() {
        token = localStorage.getItem("token");
        this.client = null;
        this.api_url = process.env.NODE_ENV === "production" ? 'https://backend.qrsocialconnect.com/api' : 'https://backend.qrsocialconnect.com/api';
        // this.api_url = process.env.NODE_ENV === "production" ? 'https://qrconnect.growcommerces.in/api' : 'http://localhost:5000/api';
    }

    init = (type) => {

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
        });

        this.client.interceptors.request.use((config) => {
            config.headers["Authorization"] = `Bearer ${token}`
            return config;
        }, (error) => {
            throw error;
        })

        return this.client;
    };

    // -------- login user
    loginUser = (data, config) => {
        return this.init().post("/admin/login", data, config)
    }

    // -------- fetch all category list
    fetchCategory = (data, config) => {
        return this.init().get(`/admin/fetch-all-category?status=${config?.statuss}&search=${config?.search}&page=${config?.page}`, data)
    }
    categoryDropdown = (data, config) => {
        return this.init().get(`/admin/fetch-all-category`, data, config)
    }

    // -------- add new category 
    addCategory = (data, config) => {
        return this.init().post("/admin/new-category", data, config)
    }

    // -------- edit the category
    editCategory = (data, config) => {
        return this.init().put("/admin/edit-category", data, config)
    }

    // -------- delete the category
    deleteCategory = (id, config) => {
        return this.init().delete(`/admin/category/${id._id}`, config)
    }

    // -------- fetch all survey-forms list
    fetchSurveyForms = (data, config) => {
        return this.init().get(`/admin/fetch-all-forms?status=${config?.statuss}&search=${config?.search}&page=${config?.page}`, data)
    }

    // -------- add new survey form
    addSurveyForm = (data, config) => {
        return this.init().post("/admin/new-survey-form", data, config)
    }

    // --------  get single survey form for edit
    singleSurvey = (data, config) => {
        return this.init().get(`/admin/fetch-form-by-id/${data}`, config)
    }
    // --------  edit survey form
    editSurveyForm = (data, config) => {
        return this.init().put(`/admin/edit-form/${config}`, data)
    }

    // -------- delete survey form
    deleteSurveyForm = (data, config) => {
        return this.init().delete(`/admin/form/${data}`, config)
    }

    // -------- fetch all clubs 
    allClubs = (data, config) => {
        return this.init().get(`/admin/fetch-all-clubs?status=${config?.statuss}&search=${config?.search}&page=${config?.page}`, data)
    }
    fetchAllClubs = (data, config) => {
        return this.init().get('/admin/fetch-all-clubs', data, config)
    }
    // -------- post new club
    addClub = (data, config) => {
        return this.init().post("/admin/new-club", data, config)
    }

    // ----------- upload image
    uploadImage = (data, config) => {
        return this.init().post("/admin/upload-file", data, config)
    }

    // ----------- get(fetch) signle club 
    getSingleClub = (data, config) => {
        return this.init().get(`/admin/fetch-club-by-id/${data}`, config)
    }
    // ----------- edit club
    editClub = (data, id) => {
        return this.init().put(`/admin/edit-club/${id}`, data)
    }
    // ----------- delete club/group
    deleteClub = (data, config) => {
        return this.init().delete(`/admin/club/${data}`, config)
    }
    // ----------- fetch all vendor/profiles
    fetchAllProfile = (data, config) => {
        return this.init().get(`/admin/fetch-all-vendor?status=${config?.statuss}&search=${config?.search}&page=${config?.page}`, data)
    }
    fetchProfileView = (data, config) => {
        return this.init().get(`/admin/club/get-vendors/${data}`, config)
    }

    fetchMembers = (data, config) => {
        return this.init().get(`/admin/club/get-followers/${data}`)
    }
    // ----------- post vendor
    addNewVendor = (data, config) => {
        return this.init().post("/admin/new-vendor", data, config)
    }
    // ----------- get single vendor
    getById = (data, config) => {
        return this.init().get(`/admin/fetch-vendor-by-id/${data}`, config)
    }
    // ----------- edit vendor
    editvendor = (data, config) => {
        return this.init().put(`/admin/edit-vendor/${data}`, config)
    }
    // ----------- delete vendor 
    deleteVendor = (data, config) => {
        return this.init().delete(`/admin/vendor/${data}`, config)
    }
    // ----------- get all posts > view > club
    posts = (id, config) => {
        return this.init().get(`/admin/fetch-all-post/${id}`, config)
    }

    getDashboardData = (data, config) => {
        return this.init().get("/admin/dashboard", data, config)
    }

    addPost = (data, config) => {
        return this.init().post("/admin/new-posts", data)
    }

    editPost = (data, config) => {
        return this.init().put(`/admin/post/${config.id}`, data);
    }

    deletePost = (data, config) => {
        return this.init().delete(`/admin/delete-post/${data}`)
    }

    getNotification = (data, config) => {
        return this.init().get(`/admin/notification?page=${data}`, data)
    }
    // ----------- upload image
    // ----------- upload image
    // ----------- upload image
}