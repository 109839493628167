import React, { useRef, useState } from 'react'
import { timeAgo } from '../helper/countTime'
import { Dot } from '../shared/svg'
import Slider from "react-slick";
import pic from "../Assets/Pic.png"
import styles from "../pages/club/club.module.css"
import { useInView } from 'react-intersection-observer';
import Video from './Video';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import Api from '../helper/Api';
import Rating from '@mui/material/Rating';
import EditPost from './EditPost';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: '#000',
    boxShadow: 24,
    borderRadius: "8px",
    overflow: "hidden"
};

const Post = ({ post, mute, setMute, setPosts, posts, setLoading, setReFetch, reFetch }) => {
    const api = new Api();
    const [viewMore, setViewMore] = useState(false)
    const user = post.vendor ? post.vendor : post.customer ? post.customer : post.adminId ? post.adminId : {}
    const postedByAdmin = post.adminId;

    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const deletePost = async () => {
        try {
            const { data, status } = await api.deletePost(post._id);

            if (status === 200) {
                const updatedPosts = posts.filter((item) => item._id !== post._id)
                setPosts(updatedPosts)
                toast.success("Deleted Successfully")
                handleClose()
            }
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message)
            } else {
                toast.error("Error Deleting Timeline")
            }
        }
    }

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        customPaging: function (i) {
            return <div className="dot"></div>;
        },
        dotsClass: "slick-dots slick-thumb",
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const handleEditClose = () => {
        setEdit(false)
    }

    const handleEdit = () => {
        setEdit(true)
        setOpen(false);
        setEditData(post);
    }

    return (
        <>
            <div >
                <EditPost setReFetch={setReFetch} reFetch={reFetch} setLoading={setLoading} open={edit} handleClose={handleEditClose} data={editData} />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ul className={styles.list_wrapper}>
                            <li onClick={handleEdit}>Edit</li>
                            <li style={{ color: "#ed4956" }} onClick={deletePost}>Remove Timeline</li>
                            <li onClick={handleClose}>Cancel</li>
                        </ul>
                    </Box>
                </Modal>
                <div className={styles.vendor}>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        {
                            user?.profileImg
                                ? <img src={user?.profileImg} className={styles.vendorpic} />
                                : <img src={pic} className={styles.vendorpic} />

                        }
                        {
                            postedByAdmin ?
                                <span>
                                    <p className={styles.vname}>Admin</p>
                                </span>
                                : post.vendor ? <span>
                                    <p className={styles.vname}>{post.vendor?.name}</p>
                                    {post.vendor?.club?.name}
                                </span> : <span>
                                    <p className={styles.vname}>{post.customer?.name}</p>
                                </span>
                        }
                    </div>
                    <div className={styles.whenposted}>{timeAgo(post.createdAt)}
                        <div style={{ gap: '3px', cursor: "pointer" }} onClick={handleOpen}><Dot /><Dot /><Dot /></div>
                    </div>
                </div>
                <Slider {...settings} className='slider'>

                    {
                        post.assets.map((asset, i) => {
                            return (
                                <div style={{ border: "none", outline: "none" }}>
                                    {
                                        asset?.type === 'video' ? <Video src={asset.src} mute={mute} setMute={setMute} width={"100%"} style={{ height: "auto", objectFit: "contain", background: "#000", aspectRatio: 1.4 }} /> : <img key={i} src={asset?.src} style={{ width: "100%", height: "auto", objectFit: "cover", aspectRatio: 1.4 }} />
                                    }
                                </div>
                            )
                        })
                    }
                </Slider>

                <div className={styles.vendor}>
                    {/* <div><Like /><Comment /><Share /></div> */}
                    <div style={{ gap: '16px', display: 'flex' }}><span>{post.totalLikes} like</span>
                        <span>{post.totalComments} comments</span></div>
                </div>
                {
                    post.rating && <Rating value={post.rating} precision={0.5} />
                }

                {
                    post.description.length > 110
                        ? <>
                            <div className={styles.thought}>
                                {
                                    viewMore ? post.description : `${post.description.slice(0, 110)}...`
                                }
                            </div>
                            {
                                viewMore ? <div className={styles.more} onClick={() => setViewMore(false)} style={{ cursor: "pointer" }} >Show less</div> : <div className={styles.more} onClick={() => setViewMore(true)} style={{ cursor: "pointer" }} >View more</div>
                            }
                        </>
                        : <div className={styles.thought}>
                            {post.description}
                        </div>
                }
                <br style={{ marginTop: '15px' }} />
            </div>
        </>
    )
}

export default Post