import React, {useEffect, useState} from 'react'
import Header from '../Header/Header.js';
import Sidebar from '../Sidebar/Sidebar.js';
import { useLocation, useNavigate } from "react-router-dom"

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1]; // ""
  const [layout, setLayout] = useState(true);
  let exist = localStorage.getItem('token')
  useEffect(()=> {
    if(!exist){
      navigate('/')
    }else {
      if(!pathname){
        navigate('/dashboard')
      }
    }
  }, exist)
  useEffect(() => {
    if (!pathname) {
      setLayout(false)
    }else{
      setLayout(true)
    }
  }, [pathname]);
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {layout ? <> <Header /> <Sidebar children={children} /></> : children}
    </div>
  )
}

export default Layout
