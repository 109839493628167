import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import profile1 from '../../Assets/profile1.png'
import profile2 from '../../Assets/profile2.png'
import profile3 from '../../Assets/profile3.png'
import profile4 from '../../Assets/profile4.png'
import Api from '../../helper/Api'

const Dashboard = () => {
    const api = new Api();
    const [data, setData] = useState({

    })

    useEffect(() => {
        const fetch = async () => {
            try {
                const { data, status } = await api.getDashboardData()

                if (status === 200) {
                    setData(data.data)
                }
            } catch (err) {
                console.log(err)
            }
        }

        fetch();
    }, [])
    return (
        <>
            <section className={styles.section}>
                <div style={{ width: "100%", background: "#fff", padding: 22 }}>
                    <h5 style={{ fontSize: "20px", fontWeight: 500, fontFamily: "Work Sans" }}>Dashboard</h5>
                </div>
                <div className={styles.container}>
                    <div className={styles.card_wrapper}>
                        <img src={profile1} />
                        <div className={styles.card_content}>
                            <h3>{data?.totalVendor}</h3>
                            <p>Vendors</p>
                        </div>
                    </div>
                    <div className={styles.card_wrapper}>
                        <img src={profile2} />
                        <div className={styles.card_content}>
                            <h3>{data?.totalCustomer}</h3>
                            <p>Customers</p>
                        </div>
                    </div>
                    <div className={styles.card_wrapper}>
                        <img src={profile3} />
                        <div className={styles.card_content}>
                            <h3>{data?.totalClubs}</h3>
                            <p>Groups</p>
                        </div>
                    </div>
                    <div className={styles.card_wrapper}>
                        <img src={profile4} />
                        <div className={styles.card_content}>
                            <h3>{data?.totalCategories}</h3>
                            <p>Categories</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard