import React, { useEffect, useState } from 'react'
import { Arrow, Left, Right, Search, Vector, Edit, Delete, View } from '../../shared/svg'
import styles from './profiles.module.css'
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { Button, Select } from '@mui/material';
import { save, selectInput } from '../../materialUI/customStyle';
import ProfileView from './ProfileView';
import Api from '../../helper/Api';
import DeleteProfile from '../../components/DeleteProfile';
import sortTable from '../../helper/sortTable';
import ascIcon from "../../Assets/asc.svg"
import dscIcon from "../../Assets/des.svg"
import defaultIcon from "../../Assets/none.svg"


const Profiles = () => {
  const navigate = useNavigate();
  const api = new Api();
  const [view, setView] = useState('')
  const [id, setId] = useState(null)
  const [statuss, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [sortBy, setSortBy] = useState("")
  const [attribute, setAttribute] = useState("")
  const [isDigit, setIsDigit] = useState(false)

  const handleOpen = (id) => {
    navigate(`/vendor/profiles/edit-vendor/${id}`)
  }
  const [alldata, setAlldata] = useState([])
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = (id) => {
    setId(id)
    setdltOpen(true)
  };
  const handledltClose = () => setdltOpen(false);
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }
  const handleView = (id) => {
    let clicked = view === id
    if (clicked) {
      setView('')
    } else {
      setView(id)
    }
  }
  const profiles = async () => {
    try {
      const { data, status } = await api.fetchAllProfile({}, { statuss, search, page });
      if (status === 200) {
        setAlldata(data.data);
        setTotalPages(data.totalPages)
      }
    } catch (err) {
      console.log(err.response.data.message)
    }
  }
  useEffect(() => {
    profiles();
  }, [refresh, statuss, search, page])

  const handleSort = (isDigit, attr) => {
    if (attr === attribute) {

      if (sortBy === "asc") {
        setSortBy("dsc")
      } else if (sortBy === "dsc") {
        setSortBy("")
      } else {
        setSortBy("asc")
      }

      setAttribute(attr)
      setIsDigit(isDigit)
    } else {
      // setting default value
      setAttribute(attr)
      setIsDigit(isDigit)
      setSortBy("asc")
    }
  }
  return (
    <div className='container'>
      <DeleteProfile handledltClose={handledltClose} dltOpen={dltOpen} id={id} refresh={refresh} setRefresh={setRefresh} />
      <div className='list' style={{ width: 'calc(100%)' }}>
        Vendor Profiles
        <br />
        <div className={styles.survey}>
          <div style={{ display: 'flex' }}>
            <Select className={styles.selectinput}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statuss}
              sx={selectInput}
              IconComponent={Arrow}
              displayEmpty
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="">status</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inActive'>Inactive</MenuItem>
            </Select>
            <div className={styles.search}><Search /> <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
          </div>
          <Button onClick={() => navigate('/vendor/profiles/add-vendor')} sx={{ ...save, width: "fit-content" }} disableElevation variant="contained">Add new Vendor </Button>
        </div>
        <div className={styles.table}>
          <div className={styles.head}>
            <p style={{ width: '8%', display: 'flex', alignItems: 'center' }}>Sl No </p>
            <p style={{ width: '17%', display: 'flex', alignItems: 'center' }}>Vendor Name <img onClick={() => handleSort(false, "name")} src={attribute === "name" && sortBy === "asc" ? ascIcon : attribute === "name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '14%', display: 'flex', alignItems: 'center' }}>Category  <img onClick={() => handleSort(false, "category.name")} src={attribute === "category.name" && sortBy === "asc" ? ascIcon : attribute === "category.name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '12%', display: 'flex', alignItems: 'center' }}>Phone Number <img onClick={() => handleSort(true, "phone")} src={attribute === "phone" && sortBy === "asc" ? ascIcon : attribute === "phone" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '17%', display: 'flex', alignItems: 'center' }}>Email Id <img onClick={() => handleSort(false, "email")} src={attribute === "email" && sortBy === "asc" ? ascIcon : attribute === "email" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '12%', display: 'flex', alignItems: 'center' }}>Club/Group <img onClick={() => handleSort(false, "club.name")} src={attribute === "club.name" && sortBy === "asc" ? ascIcon : attribute === "club.name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '11%', display: 'flex', alignItems: 'center' }}>Status <img onClick={() => handleSort(false, "status")} src={attribute === "status" && sortBy === "asc" ? ascIcon : attribute === "status" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '10%' }}>Options</p>
          </div>
          {
            sortTable(alldata, attribute, isDigit, sortBy).map((data, index) => (
              <>
                <div key={index} className={view === index + 1 ? `${styles.list} ${styles.active}` : styles.list}>
                  <p style={{ width: '8%' }}>{index + 1}</p>
                  <p style={{ width: '17%' }}>{data?.name}</p>
                  <p style={{ width: '14%' }}>{data?.category?.name}</p>
                  <p style={{ width: '12%' }}>{data?.phone}</p>
                  <p style={{ width: '17%', overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{data?.email}</p>
                  <p style={{ width: '12%' }}>{data?.club?.name}</p>
                  <p style={{ width: '10%' }}><span className={data?.status === 'inActive' ? `${styles.inactive}` : ''} style={{ width: "70px", textAlign: "center", display: "inline-block" }}>{data.status === 'active' ? 'Active' : "Inactive"}</span></p>
                  <p style={{ width: '10%', display: 'flex', alignItems: 'center', gap: '9px' }}><View handleView={handleView} id={index + 1} /><Edit handleOpen={() => handleOpen(data?._id)} /> <Delete handledltOpen={() => handledltOpen(data?._id)} /></p>
                </div>
                {view === index + 1 ? <ProfileView setView={setView} data={data} /> : ''}
              </>
            ))
          }
          <div className={styles.page}>
            Page <Left decrement={decrement} /> <div>{page}</div> <Right increment={increment} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profiles
