import React, { useEffect, useState } from 'react'
import styles from './club.module.css'
import Api from '../../helper/Api';
import { useParams } from 'react-router-dom';

const Members = ({ data }) => {

    return (
        <div className={styles.vendor_container}>
            {
                data.length === 0
                    ? <div>
                        <h4 style={{ textAlign: "center", paddingTop: 100, fontWeight: 500, fontSize: 22 }}>No Member found</h4>
                    </div>
                    :
                    data.map((member) => {
                        return (
                            <div key={member?._id} className={styles.user}>
                                <img src={member?.customer?.profileImg} className={styles.dp} />
                                <div>
                                    <p className={styles.username}>{member?.customer?.name}</p>
                                </div>
                            </div>
                        )
                    })
            }

        </div>
    )
}

export default Members