import React, { useEffect, useState } from 'react'
import styles from './sidebar.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { Add, ArrowDown, Dashboard, Notification, Survey } from './../svg';
import Button from '@mui/material/Button';
import Union from '../../Assets/Union.png'

const Sidebar = ({ children, login }) => {
    const [path, setPath] = useState("")
    const [subPath, setSubPath] = useState("")
    const location = useLocation();
    const pathname = location.pathname.split("/")[1]; // ""
    const subPathName = location.pathname.split("/")[2]; // ""

    useEffect(() => {
        setPath(pathname)
        setSubPath(subPathName)
    }, [pathname, subPathName])

    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', position: "relative" }}>
            <aside className={styles.navbar}>
                <div>
                    <div onClick={() => navigate('/dashboard')} className={path === 'dashboard' ? `${styles.menu} ${styles.activemenu}` : `${styles.menu}`}>
                        <Dashboard />
                        Dashboard</div>
                    <div onClick={() => setPath('survey')} className={path === 'survey' ? `${styles.menu} ${styles.activemenu}` : `${styles.menu}`}>
                        <Survey />
                        Survey
                        <ArrowDown vendor={path === 'survey'} />
                    </div>
                    {path === 'survey' &&
                        <>
                            <div onClick={() => navigate('/survey/category')} className={styles.submenu}>
                                <span className={styles.union}>
                                    {/* <Union /> */}
                                    <img src={Union} className={styles.svg_union} alt="" />
                                </span>
                                <div className={subPath === "category" ? styles.submenu_main_active : styles.submenu_main}>
                                    Category
                                </div>
                            </div>
                            <div onClick={() => navigate('/survey/survey-forms')} className={styles.submenu}>
                                <span className={styles.union}>
                                    {/* <Union /> */}
                                    <img src={Union} className={styles.svg_union} alt="" />
                                </span>
                                <div className={subPath === "survey-forms" ? styles.submenu_main_active : styles.submenu_main}>
                                    Survey Forms
                                </div>
                            </div>
                        </>
                    }
                    <div onClick={() => setPath('vendor')} className={path === 'vendor' ? `${styles.menu} ${styles.activemenu}` : `${styles.menu}`}>
                        <Survey />
                        Vendors
                        <ArrowDown vendor={path === 'vendor'} />
                    </div>
                    {path === 'vendor' &&
                        <>
                            <div onClick={() => navigate('/vendor/club')} className={styles.submenu}>
                                <span className={styles.union}>
                                    {/* <Union /> */}
                                    <img src={Union} className={styles.svg_union} alt="" />
                                </span>
                                <div className={subPath === "club" ? styles.submenu_main_active : styles.submenu_main}>
                                    Club/Groups
                                </div>
                            </div>
                            <div onClick={() => navigate('/vendor/profiles')} className={styles.submenu}>
                                <span className={styles.union}>
                                    {/* <Union /> */}
                                    <img src={Union} className={styles.svg_union} alt="" />
                                </span>
                                <div className={subPath === "profiles" ? styles.submenu_main_active : styles.submenu_main}>
                                    Vendor Profile
                                </div>
                            </div>
                        </>}
                    <div onClick={() => navigate('/notification')} className={styles.menu}>
                        <Notification />
                        Notification
                    </div>
                </div>
                <div className={styles.add}>
                    let's start
                    <p className={styles.small}>Creating or adding new vendor be easier</p>
                    <Button className={styles.custom} onClick={() => navigate("/vendor/profiles/add-vendor")} variant="contained"><Add /> Add New Vendor</Button>
                </div>
            </aside>
            <main className={styles.main}>
                {children}
            </main>
        </div>
    )
}

export default Sidebar
