import React, { useEffect, useState } from 'react'
import { Arrow, Left, Right, Search, Vector, Edit, Delete, View } from '../../shared/svg'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from './surveyforms.module.css'
import { Button } from '@mui/material';
import { save, selectInput } from '../../materialUI/customStyle';
import { useNavigate } from 'react-router-dom';
import Api from '../../helper/Api';
import { toast } from 'react-toastify';
import DeletSurvey from '../../components/DeleteSurvey';
import sortTable from '../../helper/sortTable';
import ascIcon from "../../Assets/asc.svg"
import dscIcon from "../../Assets/des.svg"
import defaultIcon from "../../Assets/none.svg"


const SurveyForms = () => {
  const api = new Api();
  const navigate = useNavigate();
  const [surveyforms, setSurveyforms] = useState([]);
  const [statuss, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [surveyToDelete, setSurveyToDelete] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState("")
  const [attribute, setAttribute] = useState("")
  const [isDigit, setIsDigit] = useState(false)
  const handleOpen = (id) => {
    navigate(`/survey/survey-forms/edit-survey/${id}`)
  };

  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = (id) => {
    setSurveyToDelete(id)
    setdltOpen(true);
  }
  const handledltClose = () => setdltOpen(false);
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }
  const fetchSurveyForms = async () => {
    try {
      const { data, status } = await api.fetchSurveyForms({}, { statuss, search, page });
      if (status === 200) {
        setSurveyforms(data.data);
        setTotalPages(data.totalPages)
      }
    } catch (err) {
      console.log(err.response.data.message)
    }
  }

  useEffect(() => {
    fetchSurveyForms();
  }, [refresh, statuss, search, page])
  // console.log(surveyforms)

  const handleSort = (isDigit, attr) => {
    if (attr === attribute) {

      if (sortBy === "asc") {
        setSortBy("dsc")
      } else if (sortBy === "dsc") {
        setSortBy("")
      } else {
        setSortBy("asc")
      }

      setAttribute(attr)
      setIsDigit(isDigit)
    } else {
      // setting default value
      setAttribute(attr)
      setIsDigit(isDigit)
      setSortBy("asc")
    }
  }
  return (
    <div className='container'>
      <DeletSurvey handledltClose={handledltClose} dltOpen={dltOpen} refresh={refresh} surveyToDelete={surveyToDelete} setRefresh={setRefresh} />
      <div className='list' style={{ width: 'calc(100%)' }}>
        All Survey Forms
        <br />
        <div className={styles.survey}>
          <div style={{ display: 'flex' }}>
            <Select className={styles.selectinput}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statuss}
              sx={selectInput}
              IconComponent={Arrow}
              displayEmpty
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="">status</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value="inActive">Inactive</MenuItem>
            </Select>
            <div className={styles.search}><Search /> <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
          </div>
          <Button onClick={() => navigate('/survey/survey-forms/add-survey')} sx={{ ...save, width: "fit-content" }} disableElevation variant="contained">Add new form </Button>
        </div>
        <div className={styles.table}>
          <div className={styles.head}>
            <p style={{ width: '10%', display: 'flex', alignItems: 'center' }}>Sl No </p>
            <p style={{ width: '20%', display: 'flex', alignItems: 'center' }}>Form Name <img onClick={() => handleSort(false, "name")} src={attribute === "name" && sortBy === "asc" ? ascIcon : attribute === "name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '25%', display: 'flex', alignItems: 'center' }}>Category Name <img onClick={() => handleSort(false, "category.name")} src={attribute === "category.name" && sortBy === "asc" ? ascIcon : attribute === "category.name" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '23%', display: 'flex', alignItems: 'center' }}>Form Type <img onClick={() => handleSort(false, "type")} src={attribute === "type" && sortBy === "asc" ? ascIcon : attribute === "type" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '13%', display: 'flex', alignItems: 'center' }}>Status <img onClick={() => handleSort(false, "status")} src={attribute === "status" && sortBy === "asc" ? ascIcon : attribute === "status" && sortBy === "dsc" ? dscIcon : defaultIcon} style={{ cursor: "pointer", padding: "3px", width: 15 }} /></p>
            <p style={{ width: '10%' }}>Options</p>
          </div>
          {
            sortTable(surveyforms, attribute, isDigit, sortBy).map((survey, index) => {
              return <div key={index} className={styles.list}>
                <p style={{ width: '10%' }}>{15 * (page - 1) + index + 1}</p>
                <p style={{ width: '20%' }}>{survey?.name}</p>
                <p style={{ width: '25%' }}>{survey?.category?.name}</p>
                <p style={{ width: '23%' }}>{survey?.type}</p>
                <p style={{ width: '12%' }}><span className={survey.status === 'active' ? '' : `${styles.inactive}`} style={{ width: "70px", textAlign: "center", display: "inline-block" }}>{survey.status === 'active' ? 'Active' : "Inactive"}</span></p>
                <p style={{ width: '10%', display: 'flex', alignItems: 'center', gap: '9px' }}><View handleView={() => navigate(`/survey/survey-forms/view-survey/${survey._id}`)} /><Edit handleOpen={() => handleOpen(survey._id)} /> <Delete handledltOpen={() => handledltOpen(survey._id)} /></p>
              </div>
            })
          }
          <div className={styles.page}>
            Page <Left decrement={decrement} /> <div>{page}</div> <Right increment={increment} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SurveyForms
