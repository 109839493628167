import React, { useState, useEffect } from 'react'
import { Arrow, Delete } from '../../shared/svg'
import styles from './club.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, save, formselect } from '../../materialUI/customStyle';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Api from '../../helper/Api';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as yup from "yup";
import { MdDelete } from "react-icons/md";

const ClubDetail = () => {
    const [categories, setCategories] = useState([{
        name: '', _id: ''
    }])
    const api = new Api();
    const navigate = useNavigate();
    const { reportId, id } = useParams();
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        status: yup.string().required("Staus is required"),
        category: yup.string().required("category is required"),
        description: yup.string().required("description iss required"),
        profile: yup.string().required('upload profile picture'),
        coverImg: yup.string().required('upload cover image picture')
    });

    const { errors, values, setValues, touched, resetForm } = useFormik({
        initialValues: {
            name: '',
            category: [],
            status: '',
            description: '',
            coverImg: "",
            profile: "",
        },
        validationSchema: schema,
    });

    const prevData = async () => {
        try {
            const { data, status } = await api.getSingleClub(id);
            if (status === 200) {
                setValues({ ...data.data })
            }
        } catch (err) {
            toast(err?.response?.data.message)
        }
    }
    const categoryList = async () => {
        try {
            const { data, status } = await api.categoryDropdown();
            if (status === 200) {
                setCategories(data.data)
            }
        } catch (err) {
            console.log(err?.response?.data.message)
        }
    }

    useEffect(() => {
        prevData();
        categoryList();
    }, [id])

    return (
        <div className='container'>
            <div className='list' style={{ width: '100%', paddingBottom: '2px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    View Club/Group
                    <Button onClick={() => navigate('/vendor/club')} sx={save} disableElevation variant="contained">Back </Button>
                </div>
                <form className={styles.survey} style={{ padding: "2px 36px 2px 12px" }}>
                    <span>
                        <label className={styles.title}>Club/Group Name</label>
                        <input className={styles.inputbox} name='name' value={values.name} type="text" placeholder='Enter' />
                        {
                            errors.name && touched.name && <p className={styles.error}>{errors.name}</p>
                        }
                    </span>
                    <span>
                        <label className={styles.title}>Category</label>
                        <input className={styles.inputbox} name='name' value={
                            values.category.map((cat) => {
                                return cat.name
                            }).join(", ")
                        } type="text" placeholder='Enter' />
                    </span>
                    <span>
                        <label className={styles.title}>Status</label>
                        <input className={styles.inputbox} name='name' value={values?.status} type="text" placeholder='Enter' />
                    </span>
                    <span className={styles.longtext} style={{ marginTop: '25px' }}>
                        <label className={styles.title}>Description</label>
                        <input name='description' className={styles.inputbox} value={values.description} type="text" placeholder='Enter' />
                        {
                            errors.description && touched.description && <p className={styles.error}>{errors.description}</p>
                        }
                    </span>
                    <div style={{ display: 'flex', marginTop: '25px', gap: '100px' }}>
                        <span>
                            <label className={styles.title}>Upload cover image</label>
                            <div className={styles.border}>
                                {values.coverImg ? <img src={values.coverImg} width={200} height={100} style={{ objectFit: "contain" }} alt="" />
                                    :
                                    <Button sx={save} component="label" disableElevation variant="contained">upload
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                }
                            </div>
                            <p className={styles.info} style={{ marginBottom: '15px' }}>Image size 300px*159px</p>
                            {
                                errors.coverImg && touched.coverImg && <p className={styles.error}>{errors.coverImg}</p>
                            }
                        </span>

                        <span>
                            <label className={styles.title}>Upload Profile image</label>
                            <div className={styles.border}>
                                {
                                    values.profile ? <img width={200} height={100} src={values.profile} style={{ objectFit: "contain" }} />
                                        :
                                        <Button sx={save} component="label" disableElevation variant="contained">upload
                                            <VisuallyHiddenInput type="file" />
                                        </Button>
                                }
                            </div>
                            <p className={styles.info} style={{ marginBottom: '15px' }}>Image size 300px*159px</p>
                            {
                                errors.profile && touched.profile && <p className={styles.error}>{errors.profile}</p>
                            }
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ClubDetail