import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import styles from './login.module.css'
import { Email, Pass } from '../../shared/svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import Api from '../../helper/Api';
// import Button from '@mui/material/Button';
import logo from "../../Assets/logo.png";

const Login = () => {
  const api = new Api();
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  })
  const navigate = useNavigate();
  const loginUser = async () => {
    try {
      const { data, status } = await api.loginUser(userData)
      if (status === 200) {
        toast.success('Login successfully')
        localStorage.setItem('token', data.data.token)
        return navigate('/dashboard')
      }
    } catch (err) {
      // console.log(err)
      toast.error(err.response.data.message)
      setUserData({ email: '', password: '' })
    }
  }

  return (
    <div className={styles.homepage}>
      <div className={styles.hero}>
        <div className={styles.qr}>
          <img src={logo} className={styles.logo} />
        </div>
        <div className={styles.circle}></div>
        <div className={styles.circle2}></div>
      </div>
      <div className={styles.login}>
        <div style={{ width: '65%' }}>
          <p className={styles.text}>Welcome!</p>
          <p className={styles.log}>Login to your account</p>
          <br />
          <div className={styles.input}>
            <Email />
            <input type="text" placeholder='Email Address' value={userData?.email} name="email" onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} />
          </div>
          <div className={styles.input}>
            <Pass />
            <input type="text" placeholder='Password' value={userData?.password} name='password' onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })} />
          </div>
          {/* <Button variant="contained">Contained</Button> */}
          <button className={styles.loggedin} onClick={loginUser}>login</button>
          {/* <button className={styles.forget}>Forgot Password?</button> */}
        </div>
      </div>
    </div>
  )
}

export default Login
