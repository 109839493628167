import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer';


const Video = ({ src, width, height, mt, mute, setMute, style = {} }) => {
    const videoRef = useRef(null)

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.9,
        rootMargin: "0px 0px 100px 0px"
    });

    useEffect(() => {
        console.log("pause", videoRef.current.paused)
        if (inView) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            }
        } else {
            if (!videoRef.current.paused) {
                videoRef.current.pause();
            }
        }
    }, [inView])

    return (
        <div ref={ref} onClick={() => setMute(!mute)}>
            <video ref={videoRef} src={src} loop autoPlay={inView} muted={!mute} style={{ width: width, height: height, marginTop: mt, objectFit: "cover", ...style }} />
        </div>
    )
}

export default Video