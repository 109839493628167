import React, { useEffect, useState } from 'react'
import { Arrow, Delete, Edit, Left, Right, Search, Vector, View } from '../../shared/svg'
import styles from './notification.module.css'
import { useNavigate } from 'react-router-dom';
import Api from '../../helper/Api';
import DeleteClub from '../../components/DeleteClub';
import userImg from "../../Assets/profile.jpg"
import { timeAgo } from '../../helper/countTime';
const Notification = () => {
  const api = new Api();
  const [refresh, setRefresh] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [notification, setNotification] = useState([])

  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }
  
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  const fetchNotification = async () => {
    try {
      const { data, status } = await api.getNotification(page)

      if (status === 200) {
        setNotification(data.data)
        setTotalPages(data.totalPages)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchNotification()
    const interval = setInterval(() => {
      fetchNotification();
    }, 3000);

    return () => {
      clearInterval(interval)
    }
  }, [page])  // console.log(clubs)
  return (
    <div className='container'>
      {/* <DeleteClub handledltClose={handledltClose} dltOpen={dltOpen} deleteclub={deleteclub} refresh={refresh} setRefresh={setRefresh} /> */}
      <div className='list' style={{ width: 'calc(100%)' }}>
        Notification
        <br />

        <div className={styles.table}>
          {
            notification.map((item) => {
              const user = item.vendor
              return (
                <div key={item._id} style={{ borderBottom: "1px solid #aaaaaa", padding: "10px 0", display: "flex", marginTop: 10, justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <img src={user.profileImg} style={{ width: 35, height: 35, borderRadius: "50%", objectFit: "cover" }} alt="" />
                    <p style={{ fontSize: 16, color: "#3A3A49" }}>{item.message}</p>
                  </div>
                  <p style={{ color: "#3A3A49", fontSize: 14 }}>
                    {timeAgo(item.createdAt)}
                  </p>
                </div>
              )
            })
          }
          <div className={styles.page}>
            Page <Left decrement={decrement} /> <div>{page}</div> <Right increment={increment} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification
