import React from 'react'
import styles from './profiles.module.css'
import { Button } from '@mui/material'
import { googlemap } from '../../materialUI/customStyle'
import { ExitButton } from '../../shared/svg'
import Api from '../../helper/Api'
import { useParams } from 'react-router-dom'
import profile from '../../Assets/profile.jpg'
import qr from '../../Assets/qr.jpg'
import QRCode from "react-qr-code";

const ProfileView = ({ setView, data }) => {
  return (
    <div className={styles.profile_view}>
      <div className={`${styles.profile_box} ${styles.box_content}`}>
        <img src={data?.profileImg ? data?.profileImg : profile} className={styles.dp} />
        <p className={styles.dpname}>{data?.name}</p>
        <p style={{ padding: '5px' }}>{data?.category?.name}</p>
      </div>
      <div style={{ display: "flex", width: "100%", gap: 13, flexDirection: "column" }}>
        <div className={styles.box_contentwo}>
          <div className={styles.profile_box}>
            <span>
              <p>Phone number</p>
              <p className={styles.equal}>:</p>
              <p className={styles.details}>{data?.phone}</p>
            </span>
            <span>
              <p>Email Address  </p>
              <p className={styles.equal}>:</p>
              <p className={styles.details}>{data?.email}</p>
            </span>
            <span>
              <p>Group/Club  </p>
              <p className={styles.equal}>:</p>
              <p className={styles.details}>{data?.club?.name}</p>
            </span>
            <span style={{ marginBottom: '10px' }}>
              <p> Address  </p>
              <p className={styles.equal}>:</p>
              <p className={styles.details}>{data?.address}</p>
            </span>
          </div>
          <div className={styles.map}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.0542923488924!2d77.20288677495883!3d28.68802248155965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd8d7f3807ab%3A0x3ba1a4f4de152e0!2sDaulat%20Ram%20College!5e0!3m2!1sen!2sin!4v1704565592215!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <Button sx={googlemap} disableElevation variant="contained">View map </Button>
          </div>
        </div>
        <div className={styles.locate}>
          <div className={styles.profile_box}>
            <span>
              <p>Opening Hours</p>
              <p className={styles.equal}>:</p>
              <p className={styles.details} style={{ textTransform: "capitalize" }}>{data?.workingHours[0]} - {data?.workingHours[1]}</p>
            </span>
            <span>
              <p>Services</p>
              <p className={styles.equal}>:</p>
              <p className={styles.details}>{data?.services}</p>
            </span>
            <span style={{ marginBottom: '14px' }}>
              <p>Highlights</p>
              <p className={styles.equal}>:</p>
              <p className={styles.details}>{data?.highlights}</p>
            </span>
          </div>
          <div className={styles.profile_box} style={{ width: "20%" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <QRCode value={`https://qrsocialconnect.com/vendor/${data._id}`} size={80} />
              {/* <img src={qr} style={{ width: '80px', height: '80px' }} /> */}
            </div>
          </div>
        </div>
      </div>
      <ExitButton setView={setView} />
    </div>
  )
}

export default ProfileView
