import React, { useEffect, useState } from 'react'
import { Arrow, Attechment, Dot, Emoji, Like, Mark, Send, Share, Comment, Closed, Opened } from '../../shared/svg'
import styles from './club.module.css'
import { save } from '../../materialUI/customStyle';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Vendors from './Vendors';
import Api from '../../helper/Api';
import { styled } from '@mui/material/styles';
import Members from './Members';
import { toast } from 'react-toastify';
import Loader from "../../components/Loader";
import Post from '../../components/Post';
import Picker from 'emoji-picker-react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Video from '../../components/Video';
import Slider from "react-slick";

const ClubView = () => {
    const navigate = useNavigate();
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const api = new Api();
    const [mute, setMute] = useState(false)
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(false);
    const [tab, setTab] = useState(1);
    const [members, setMembers] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [description, setDescription] = useState("");
    const [fileResponse, setFileResponse] = useState([]);
    const [openEmoji, setOpenEmoji] = useState(false)
    const [posts, setPosts] = useState([{
        isSurvey: '',
        club: '',
        description: '',
        assets: [],
        totalComments: '',
        totalLikes: '',
        vendor: {},
        admin: ''
    }])

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        customPaging: function (i) {
            return <div className="dot"></div>;
        },
        dotsClass: "slick-dots slick-thumb",
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [profileDetail, setProfileDetail] = useState({
        name: '',
        category: [],
        profile: '',
        description: '',
        coverImg: ''
    });

    const onEmojiClick = (emojiData) => {
        setDescription(`${description}${emojiData.emoji}`)
    };

    const viewPage = async () => {
        try {
            const { data, status } = await api.getSingleClub(id);
            if (status === 200) {
                setProfileDetail(data.data)
            }
        } catch (err) {
            console.log(err.response.data.message)
        }
    }

    const otherPosts = async () => {
        try {
            const { data, status } = await api.posts(id);
            if (status === 200) {
                setPosts(data.data)
            }
        } catch (err) {
            console.log(err.response.data.message)
        }
    }

    const fetchMembers = async () => {
        try {
            const { data, status } = await api.fetchMembers(id)
            if (status === 200) {
                setMembers(data.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const fetchVendors = async () => {
        try {
            const { data, status } = await api.fetchProfileView(id);
            if (status === 200) {
                setVendors(data.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        viewPage();
        otherPosts();
    }, [id, reFetch])

    useEffect(() => {
        if (tab === 1) {
            fetchMembers();
        } else {
            fetchVendors();
        }
    }, [id, tab])

    const handlePost = async () => {
        try {
            setLoading(true)
            const club = id;

            // check if all fields are filled
            if (!description) {
                toast.error("Please write some description")
            }
            else if (fileResponse.length === 0) {
                toast.error("Please upload atlease 1 image")
            } else {
                // upload images
                let assets = []
                for (let i = 0; i < fileResponse.length; i++) {
                    const formData = new FormData();
                    formData.append("image", fileResponse[i])
                    const { data, status } = await api.uploadImage(formData)

                    if (status === 200) {
                        assets.push({
                            src: data.data[0],
                            type: fileResponse[i].type === "video/mp4" ? "video" : "img"
                        })
                    }
                }

                const { data, status } = await api.addPost({
                    club,
                    description,
                    assets
                })

                if (status === 200) {
                    setLoading(false)
                    setReFetch(!reFetch)
                    setDescription("")
                    setFileResponse([])
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const handleEmoji = async () => {
        setOpenEmoji(!openEmoji)
    }
    return (
        <>
            <div className='insights' style={{ position: "relative" }}>
                {
                    loading && <div style={{ position: "fixed", zIndex: 5, display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh", top: 0, left: 0, background: "rgba(0, 0, 0, 0.4)" }}>
                        <Loader />
                    </div>
                }
                <div className='list' style={{ width: '100%', marginBottom: '22px', padding: 20 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', fontWeight: 500, justifyContent: 'space-between' }}>
                        Posts/Insights
                        <Button onClick={() => navigate('/vendor/club')} sx={save} disableElevation variant="contained">Back </Button>
                    </div>
                </div>
                <div className='insightcontainer'>
                    <div className="list" style={{ width: 'calc(100% - 40%)', borderRadius: "30px 30px 0px 0px", padding: 0, paddingBottom: '110px', position: 'relative' }}>
                        <img src={profileDetail.coverImg} className={styles.posts} />
                        <img src={profileDetail.profile} className={styles.person} />

                        <div style={{ padding: 22 }}>

                            <h2 className={styles.clubname}>{profileDetail.name}</h2>
                            <div style={{ display: "flex", gap: "7px", flexWrap: "wrap" }}>
                                {
                                    profileDetail.category?.map((cat) => (
                                        <p className={styles.subhead}>{cat.name}</p>
                                    ))
                                }
                            </div>
                            <p className={styles.para}>{profileDetail.description}</p>
                            <div className={styles.postinfo}>
                                <AvatarGroup total={profileDetail?.totalFollowers} sx={{
                                    ".MuiAvatarGroup-avatar": {
                                        width: "22px !important",
                                        height: "22px !important",
                                        fontSize: 12
                                    }
                                }}>
                                    {
                                        members.map((member, i) => (
                                            <Avatar key={i} sx={{ width: "22px", height: "22px" }} src={member?.customer?.profileImg} />
                                        ))
                                    }
                                </AvatarGroup>
                                {profileDetail.totalFollowers} followers
                            </div>
                            <p className={styles.timeline}>Timeline</p>
                            <hr className={styles.line} />
                            <div className={styles.commentbox}>
                                <textarea className={styles.comment} onChange={(e) => setDescription(e.target.value)} value={description} placeholder='Start from here' name="comment" cols="30" rows="10"></textarea>

                                <div className={styles.attech}>
                                    {/* <Mark /> */}
                                    <Emoji onClick={handleEmoji} />
                                    <input type='file' id='files' multiple accept='image/png, image/jpeg, video/mp4' component="label" name='files' style={{ visibility: 'hidden', display: "none" }} onChange={(e) => setFileResponse([...e.target.files])} disableElevation variant="contained">
                                        {/* <VisuallyHiddenInput type="file" multiple /> */}
                                    </input>
                                    <label htmlFor="files"><Attechment /> </label>
                                    <span onClick={handlePost} style={{ cursor: "pointer" }}>Post <Send /></span>
                                </div>
                                {
                                    openEmoji &&
                                    <Picker onEmojiClick={onEmojiClick} style={{ width: "100%", marginTop: 10 }} />
                                }

                                <Slider {...settings} className='slider'>
                                    {
                                        fileResponse.map((asset, i) => {
                                            return (
                                                <div style={{ border: "none", outline: "none" }}>
                                                    {
                                                        asset?.type === 'video/mp4' ? <Video src={URL.createObjectURL(asset)} mute={true} setMute={() => { }} width={"100%"} height={'auto'} style={{ aspectRatio: 1.8 }} mt={0} /> : <img key={i} src={URL.createObjectURL(asset)} style={{ width: "100%", height: "auto", aspectRatio: 1.8, objectFit: "cover" }} />
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            {posts.map((post, i) => (
                                <Post post={post} setLoading={setLoading} setReFetch={setReFetch} reFetch={reFetch} key={i} mute={mute} setMute={setMute} setPosts={setPosts} posts={posts} />
                            ))
                            }
                        </div>
                    </div>
                    <div className="list" style={{ width: '40%' }}>
                        <div className={styles.navigate}>
                            <div className={styles.smallhead} onClick={() => setTab(1)}><p className={tab === 1 ? `${styles.opened}` : `${styles.closed}`}>Members</p>
                                <div style={{ width: "100%", height: "4px", background: tab === 1 ? "#000" : "rgba(0, 0, 0, 0.10)", borderRadius: 10 }}></div>
                            </div>
                            <div className={styles.smallhead} onClick={() => setTab(2)}><p className={tab === 2 ? `${styles.opened}` : `${styles.closed}`}>Vendors{` (${profileDetail?.totalVendor})`}</p>
                                <div style={{ width: "100%", height: "4px", background: tab === 2 ? "#000" : "rgba(0, 0, 0, 0.10)", borderRadius: 10 }}></div>
                            </div>
                        </div>
                        {tab === 1 && <Members data={members} />}
                        {tab === 2 && <Vendors data={vendors} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClubView